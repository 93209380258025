import { useState } from 'react'

import { loginUrl } from '../../../store.config'
import CollapsibleButton from './CollapsibleButton'

import './question-style.scss'

const FaqQuestions = () => {
  const [clicked, setClicked] = useState<string[]>([])

  const size = 13
  const thickness = 2
  const color = '#000'

  const handleCollapsible = (key: string) => {
    if (clicked.includes(key)) {
      setClicked(clicked.filter((keys) => keys !== key))

      return
    }

    setClicked((prevState) => [...prevState, key])
  }

  return (
    <>
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-1')}
        >
          <span>O que é o Clube Decathlon?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-1')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-1') ? 'block' : 'hidden'
          }`}
        >
          O Clube Decathlon é o nosso programa de relacionamento que oferece
          benefícios exclusivos para clientes cadastrados. Para aproveitar os
          benefícios basta se cadastrar e se identificar em suas compras através
          do CPF.
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-2')}
        >
          <span>Como participar?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-2')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-2') ? 'block' : 'hidden'
          }`}
        >
          Basta se cadastrar no site{' '}
          <a href={`${loginUrl}?returnUrl=https://www.decathlon.com.br`}>
            clicando aqui
          </a>
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-3')}
        >
          <span>Como meus dados são utilizados?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-3')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-3') ? 'block' : 'hidden'
          }`}
        >
          Os seus dados esportivos (ou seja, todos os dados que você compartilha
          conosco...) são apenas para a DECATHLON. Para nós é importante
          garantir que não compartilhamos os seus dados pessoais. Estamos
          comprometidos em nunca vender ou locar esses dados, isso é importante
          para nós. Você poderá{' '}
          <a href="https://www.decathlon.com.br/servicos/politica-de-privacidade">
            encontrar nesta
          </a>{' '}
          página todos os tratamentos relacionados com os seus dados pessoais.
          Onde, quando, como e porque tratamos os seus dados e também quais são
          os seus direitos.
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-4')}
        >
          <span>Quais são os benefícios do Clube Decathlon?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-4')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-4') ? 'block' : 'hidden'
          }`}
        >
          Os benefícios do clube Decathlon são descontos em produtos
          selecionados, sorteios exclusivos, extensão do período de trocas,
          trocas sem cupom, entre outros. Eles estão destacados na página{' '}
          <a href="https://www.decathlon.com.br/clube">
            www.decathlon.com.br/clube
          </a>{' '}
          com a informação completa.
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-5')}
        >
          <span>Como utilizar os benefícios?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-5')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-5') ? 'block' : 'hidden'
          }`}
        >
          Os benefícios relacionados a uma compra como revisão de bike,
          encordoamento de raquetes, extensão de trocas, descontos, extensão de
          garantia e sorteios só poderão ser utilizados para compras
          cadastradas. Elas serão cadastradas se você se identificar com seu CPF
          na hora de sua compra, não vale CPF na nota, precisa ser o CPF
          atrelado ao Clube Decathlon. Para alguns benefícios você receberá um
          e-mail com as principais informações. Você pode ativar o recebimento
          de comunicação na{' '}
          <a href="https://www.decathlon.com.br/_secure/account/orders#/comunicacao">
            sua conta
          </a>
          . Para os eventos esportivos e para as dicas, basta acessar o nosso
          portal para se inscrever e conferir. Para o empréstimo de raquetes
          você pode consultar diretamente com sua loja de preferência.
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-6')}
        >
          <span>Posso transferir meus benefícios para outra pessoa?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-6')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-6') ? 'block' : 'hidden'
          }`}
        >
          Não, seus benefícios são exclusivos do seu CPF.
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-7')}
        >
          <span>Como fico sabendo das novidades do clube?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-7')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-7') ? 'block' : 'hidden'
          }`}
        >
          Ao ativar o recebimento de emails marketing em sua conta você fica por
          dentro de todas novidades do clube. Para ativar: Acesse sua conta{' '}
          {'>'} Minhas Preferências {'>'} Minhas comunicações
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-8')}
        >
          <span>Como consultar minhas compras cadastradas?</span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-8')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-8') ? 'block' : 'hidden'
          }`}
        >
          Você pode acessar a sua conta para acessar suas compras em{' '}
          <a href="https://www.decathlon.com.br/_secure/account/store-orders">
            lojas
          </a>{' '}
          ou{' '}
          <a href="https://www.decathlon.com.br/_secure/account/orders#/orders">
            site.
          </a>
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-9')}
        >
          <span>
            Fiz uma compra no site, estou cadastrado no Clube Decathlon?
          </span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-9')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-9') ? 'block' : 'hidden'
          }`}
        >
          Sim, se você concorda com nossa política de privacidade.
        </div>
      </div>
      {/* */}
      <div className="faq-question">
        <button
          className="faq-question-title"
          onClick={() => handleCollapsible('faq-question-10')}
        >
          <span>
            Fiz uma compra na loja e passei o CPF na nota, depois me cadastrei
            no Clube Decathlon. Tenho acesso a ela?
          </span>
          <CollapsibleButton
            color={color}
            size={size}
            thickness={thickness}
            openOrClose={clicked.includes('faq-question-10')}
          />
        </button>
        <div
          className={`faq-question-content ${
            clicked.includes('faq-question-10') ? 'block' : 'hidden'
          }`}
        >
          Não. O acesso às compras clube e benefícios é a partir do momento em
          que o cadastro é feito e mediante a identificação no caixa.
        </div>
      </div>
    </>
  )
}

export default FaqQuestions
