import { useRef, useState, Fragment } from 'react'
import type { ClubeDecathlonQuery } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import Button from 'src/components/ui/Button'

import CloseButton from './CloseButton'

import './clubeStyles.scss'

type BenefitsProps = {
  benefitsTitle: string
  icon: string
  image: string
  link: string
  paragraphs: string[]
}

const BenefitsClube = ({
  reference,
}: {
  reference: React.MutableRefObject<HTMLDivElement | null>
}) => {
  const { allCmsClubeDecathlon: nodes } =
    useStaticQuery<ClubeDecathlonQuery>(database)

  const { benefits }: { benefits: BenefitsProps[] } =
    nodes?.edges[0]?.node?.sections[0]?.data

  const [benefitsState, setBenefitsState] = useState<BenefitsProps[]>(benefits)
  const [click, setClick] = useState(false)
  const [objView, setObjView] = useState<BenefitsProps | Record<string, never>>(
    {}
  )

  const mainBenefit = useRef<null | HTMLDivElement>(null)

  const handleObjViewTrue = (bene: BenefitsProps) => {
    setClick(true)
    const tempBenefits = benefits.filter(
      (item) => item.benefitsTitle !== bene.benefitsTitle
    )

    setObjView(bene)
    tempBenefits.unshift(bene)
    setBenefitsState(tempBenefits)
  }

  const handleObjViewFalse = () => {
    setClick(false)
    setBenefitsState(benefits)
  }

  const checkFirstCell = (idx: number) => {
    return click && idx === 0
  }

  const scrollToDiv = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    ref?.current?.scrollIntoView()
  }

  return (
    <section className="benefits-section block pp:px-10 md:px-[84px] pb-20 bg-black">
      <div className="benefits-buttons flex justify-center pp:flex-col pp:gap-1 pp:py-8 sm:flex-row sm:justify-evenly sm:py-16">
        <Button
          type="button"
          aria-label="Direcionar para os benefícios"
          className="benefits-button relative benefits pointer text-sm text-white max-h-10 !py-[7px] !px-[14px] pp:mx-auto md:mx-0 font-bold w-64 uppercase border-[3px] border-white"
          onClick={() => scrollToDiv(mainBenefit)}
        >
          benefícios
        </Button>
        <Button
          type="button"
          aria-label="Direcionar para perguntas frequentes"
          className="benefits-button relative faq pointer text-sm text-white max-h-10 !py-[7px] !px-[14px] pp:mx-auto md:mx-0 font-bold w-64 uppercase border-[3px] border-white"
          onClick={() => scrollToDiv(reference)}
        >
          faq
        </Button>
      </div>
      <h2
        className="benefits-title text-3xl uppercase text-white text-center font-bold leading-[2.53] pp:my-0 pp:mb-9 my-9"
        ref={mainBenefit}
      >
        benefícios
      </h2>
      <div className="grid-benefits grid mx-auto max-w-[1200px] pp:grid-cols-1 pp:gap-4 sm:grid-cols-2 md:grid-cols-3 gap-2">
        {benefitsState?.map((benefit: BenefitsProps, index: number) => {
          return (
            <div
              key={`benefit-${index}`}
              className={`benefit-cell relative group border-[1px] border-[#333333] cursor-pointer flex pp:flex-col sm:flex-row hover:bg-gradient-to-t from-[#296ffa] via-[#0073b3] to-[#451acf] ${
                checkFirstCell(index)
                  ? 'pp:row-span-1 pp:col-span-1 sm:row-span-2 sm:col-span-2 pp:max-h-max sm:max-h-[440px] justify-between bg-gradient-to-t from-[#296ffa] via-[#0073b3] to-[#451acf]'
                  : 'justify-center'
              }`}
              onClick={() => {
                objView?.benefitsTitle !== benefit?.benefitsTitle &&
                  handleObjViewTrue(benefit)
                scrollToDiv(mainBenefit)
              }}
              aria-hidden="true"
            >
              <div
                className={`close-cell absolute top-5 right-5 z-10 ${
                  checkFirstCell(index) ? 'block' : 'hidden'
                }`}
                onClick={() => {
                  handleObjViewFalse()
                }}
                aria-hidden="true"
              >
                <CloseButton size={20} thickness={4} color="#7a7a7a" />
              </div>
              <div
                className={`cell-main flex flex-col items-center pp:py-6 sm:py-4 lg:py-8 px-5 ${
                  checkFirstCell(index) ? 'pp:w-full sm:w-[50%]' : ''
                }`}
              >
                <div
                  className={`cell-icon w-[70px] h-[70px] rounded-full flex justify-center items-center bg-gradient-to-t from-[#296ffa] via-[#0073b3] to-[#451acf] group-hover:bg-gradient-to-tr group-hover:from-[#ffffff] group-hover:to-[#f2f2f2] ${
                    checkFirstCell(index)
                      ? 'bg-gradient-to-tr from-[#ffffff] via-[#ffffff] to-[#f2f2f2]'
                      : ''
                  }`}
                >
                  <img
                    src={`${benefit?.icon}`}
                    alt={`${benefit?.benefitsTitle}`}
                    className={`max-w-[39px] h-auto align-middle group-hover:filter group-hover:invert-[25%] ${
                      checkFirstCell(index) ? 'filter invert-[25%]' : ''
                    }`}
                  />
                </div>
                <h4 className="text-white text-lg font-bold uppercase text-center my-[1.33em]">
                  {benefit?.benefitsTitle}
                </h4>
                <div
                  className={`cell-paragraphs relative pt-[45px] ${
                    checkFirstCell(index) ? 'block' : 'hidden'
                  }`}
                >
                  <span className="paragraph-before absolute block bg-white w-[150px] h-[3px] top-0 left-1/2 -translate-x-1/2" />
                  <span className="paragraph-after absolute block w-0 h-0 border-l-[15px] border-r-[15px] border-t-[15px] border-l-transparent border-r-transparent border-t-white top-0 left-1/2 -translate-x-1/2" />
                  {benefit?.paragraphs.map((paragraph: string, idx: number) => {
                    return (
                      <Fragment key={`paragraph-${idx}`}>
                        <p className="text-center text-base font-light text-white">
                          {paragraph}
                        </p>
                        <br />
                      </Fragment>
                    )
                  })}
                  {benefit?.link && (
                    <a
                      href={benefit?.link}
                      className="block font-bold underline text-white text-center hover:no-underline"
                    >
                      Clique aqui
                    </a>
                  )}
                </div>
              </div>
              <div
                className={`cell-side-image ${
                  checkFirstCell(index)
                    ? 'block pp:w-full sm:w-[50%]'
                    : 'hidden'
                }`}
              >
                <img
                  src={benefit?.image}
                  alt={benefit?.benefitsTitle}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const database = graphql`
  query ClubeDecathlon {
    allCmsClubeDecathlon(
      filter: { id: { eq: "fb3eab07-3d95-5ffc-8528-c6d878d53d3f" } }
    ) {
      edges {
        node {
          id
          sections {
            data
          }
        }
      }
    }
  }
`

export default BenefitsClube
