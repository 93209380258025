import type { CSSProperties } from 'react'

type Props = {
  color: string
  size: number
  thickness: number
  openOrClose: boolean
}

const CollapsibleButton = ({
  color,
  size,
  thickness,
  openOrClose = true,
}: Props) => {
  const style1: CSSProperties = {
    position: 'absolute',
    width: openOrClose ? '0' : `${thickness}px`,
    height: '100%',
    top: '0',
    left: '50%',
    backgroundColor: `${color}`,
    transform: 'translateX(-50%)',
  }

  const style2: CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: `${thickness}px`,
    top: '50%',
    left: '0',
    backgroundColor: `${color}`,
    transform: 'translateY(-50%)',
  }

  const divStyle: CSSProperties = {
    position: 'relative',
    display: 'inline-block',
    width: `${size}px`,
    height: `${size}px`,
  }

  return (
    <div style={divStyle}>
      <span style={style1} />
      <span style={style2} />
    </div>
  )
}

export default CollapsibleButton
