import FaqQuestions from './FaqQuestions'

const FaqClubeDecathlon = ({
  reference,
}: {
  reference: React.MutableRefObject<HTMLDivElement | null>
}) => {
  return (
    <section ref={reference} className="faq-clube">
      <div className="faq-title pp:px-5 md:px-[84px] py-9 block bg-black">
        <h2 className="text-3xl uppercase text-center text-white pp:leading-[1.2] md:leading-[2.53] font-bold">
          perguntas frequentes
        </h2>
      </div>
      <div className="faq-questions flex flex-col gap-[10px] justify-center pp:px-[30px] md:px-[84px] py-[60px] bg-gradient-to-tr from-[#f7f7f7] to-[#d9d9d9]">
        <FaqQuestions />
      </div>
    </section>
  )
}

export default FaqClubeDecathlon
